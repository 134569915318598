// 申遗承诺
// import SHZT from './sn-table-column-shzt';
// import DJZT from './sn-table-column-djzt';
// SHZT,
// DJZT
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "景点名称",
                    prop: "LYJDMC",
                    minWidth: ""
                },
                {
                    label: "时间",
                    prop: "JCRQ",
                    minWidth: ""
                },
                {
                    label: "日游客量",
                    prop: "YKL",
                    minWidth: ""
                }
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
};
export default selfData;